import React, { useState } from "react";
import { Link } from "react-router-dom";
import ListGrave from "./UserTabs/ListGrave";
import SearchGrave from "./UserTabs/SearchGrave"
import HeaderUi from "./headerUi";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
const User = (props: any) => {
  const { setTab, tab } = props;
  const [showForm, setShowForm] = useState(true);
  const [graveData, setGraveData] = useState([])

  return (
    <div >
      {!tab && <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: "#3B313F" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              {/* <MenuIcon /> */}
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Marlboro Muslim Memorial
            </Typography>
            <Link to="/login">
              <Button color="inherit">Login</Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>}
      <div className={`${tab ? 'w-[1500px] items-center justify-center  flex mb-20' : 'p-4  place-items-center h-screen mx-auto flex flex-col'}`}>
        {!tab && <div className=" w-[100%] flex justify-end my-2 mt-5"> </div>}
        {showForm ? <SearchGrave showForm={showForm} tab={tab} setTab={setTab} setShowForm={setShowForm} setGraveData={setGraveData} /> : <ListGrave showForm={showForm} tab={tab} setTab={setTab} setShowForm={setShowForm} graveData={graveData} />}
      </div>
    </div>
  )
}

export default User;