import React from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const Selectfeild = (props: any) => {


    const { name, control, required, label, fullWidth, disabled, getMenu, menu } = props;
    console.log("testingg", menu);

    return (
        <div>
            <FormControl fullWidth={fullWidth}>
                <InputLabel sx={{ color: "gray", backgroundColor: 'white', lineHeight: "15px" }}>{label}</InputLabel>
                <Controller

                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            size="small"
                            label={label}
                            onOpen={getMenu}
                            onChange={onChange}
                            value={value}
                            sx={{
                                color: "black", border: '1px solid grey',
                                '& .MuiFormLabel-root': {
                                    lineHeight: '1.0375em'
                                }
                            }}
                        >
                            {menu.map((item: any) => {
                                return (<MenuItem key={item.value} value={item.value}>{item.option}</MenuItem>)
                            })}
                        </Select>
                    )}
                />
            </FormControl>
        </div>
    )
}

export default Selectfeild;