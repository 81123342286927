import React from "react";
// import TextField from '@mui/material/TextField';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HeaderUi from "./headerUi";
import axios from "axios";
import API from "../endpoints/endpoints";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import HeaderBox from '../layout/HeaderBox';


const Login = () => {

    const history = useHistory();

    const [formData, setFormData] = useState({ username: "", password: "" });
    const [loginState, setLoginState] = useState<any>(false);

    const handleChange = (type: string, value: string) => {
        let tempData: any = formData;
        tempData[type] = value;
        setLoginState(false)
        setFormData(tempData);
    }

    const handleClick = async (route: string) => {
        localStorage.setItem("Login", 'false');
        await API.login(formData).then((res: any) => {
            // console.log("LOGINRES", res.data.status)
            if (res?.data?.status) {
                if (res.data.status === "success") {
                    history.push('/admin')
                } else {
                    localStorage.setItem("Login", 'true');
                    setLoginState(true)
                }
            }
        }).catch((error) => {
            localStorage.setItem("Login", 'true');
            setLoginState(true)
        })






        // history.push(`/${route}`)
    }


    return (

        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ backgroundColor: "#3B313F" }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            {/* <MenuIcon /> */}
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Marlboro Muslim Memorial
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <div className=" grid  place-items-center h-screen">
                <div className="w-[40%] h-auto bg-white rounded-[24px] px-5 pb-5">
                    <div className="m-5">
                        <span className="block text-2xl font-bold text-center text-[#4b4b4b]"><h4>Administrator Login</h4></span>
                    </div>

                    <form className="pt-6 pb-2 px-8">
                        {loginState &&
                            <div >
                                <span className="block font-bold text-center text-[#f1592a]">Invalid Credentials</span>
                            </div>}
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="username">
                                Admin Username
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" onChange={(e) => handleChange("username", e.target.value)} />
                        </div>
                        <div className="mb-6">
                            <label className="block text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(e) => handleChange("password", e.target.value)} />
                        </div>
                        <div className="text-center">
                            <button className="styledButton1  py-2 px-6 rounded focus:outline-none focus:shadow-outline " type="button" onClick={() => handleClick('admin')}>
                                Sign In
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;