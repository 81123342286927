import React from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import TextField from '@mui/material/TextField';

const SelectDate = (props: any) => {


    const { name, control, required, label, fullWidth, disabled, format } = props;

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        size="small"
                        sx={{
                            input: { color: 'grey', border: '1px solid grey', borderRadius: '5px' },
                            svg: { color: 'grey' },
                            label: { color: 'grey' },
                            '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
                                backgroundColor: '',
                                color: 'grey'
                            },
                            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                color: 'grey'
                            },
                            '.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                                color: 'grey', backgroundColor: "white"
                            }
                        }}
                        required={required}
                        label={label}
                        type="date"
                        defaultValue="05-24-2017"
                        InputLabelProps={{
                            shrink: true,

                        }}


                        InputProps={{ inputProps: { min: "1900-05-01" } }}
                        fullWidth={fullWidth}
                        disabled={disabled}
                        onChange={onChange}
                        value={value}
                    />
                )}
            />
        </div>
    )
}

export default SelectDate;