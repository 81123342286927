import axios from "axios";
const filter_type = "grave";
const status = "Unassigned";

const base = 'https://app.marlboromuslimmemorial.com/v1'
// const base = "http://192.168.1.11:9001/v1"

const API = {
    getSectionMenu: () => {
        return axios.post(`${base}/getSection`);
    },
    getSectionNumber: () => {
        return axios.post(`${base}/getSection`);
    },
    getOrgMenu: () => {
        return axios.post(`${base}/getOrganization`)
    },

    getOrgMenuFilter: () => {
        return axios.post(`${base}/getOrganization`)
    },
    addGrave: (data: any) => {
        return axios.post(`${base}/addGrave`, { ...data })
    },
    updateAssign: (uuid: any, data: any) => {
        return axios.put(`${base}/updateDeceased`, { uuid, ...data })
    },
    getGrave: () => {
        return axios.post(`${base}/getGrave`)
    },
    getGraveFilter: (section_id: any, organization_id: any) => {
        return axios.post(`${base}/getGrave`, { section: section_id, organization_id, status })
    },

    addSection: (data: any) => {
        return axios.post(`${base}/addSection`, { ...data })
    },
    getSection: () => {
        return axios.post(`${base}/getSection`)
    },

    addOrganization: (data: any) => {
        return axios.post(`${base}/addOrganization`, { ...data })
    },
    getOrganization: () => {
        return axios.post(`${base}/getOrganization`)
    },

    getGraveId: () => {
        return axios.put(`${base}/unassignedGraves`)
    },
    assignGrave: (data: any) => {
        return axios.post(`${base}/createDeceased`, { ...data })
    },

    searchGrave: (data: any) => {
        return axios.post(`${base}/getDeceased`, { ...data })
    },
    login: (data: any) => {
        return axios.post(`${base}/login`, { ...data })
    },
}

export default API;
