import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import User from "./pages/User";
import Graves from './pages/AdminTabs/Grave/Graves';
import HeaderBox from "./layout/HeaderBox";
function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path="/admin"> <Admin /> </Route>
          <Route path="/login"> <Login /> </Route>
           <Route path="/"> <User /> </Route>
           <Route path = "/admin/graves"> <Graves/> </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
