import React from "react";
import { useState, useEffect } from "react";
import HeaderBox from "../layout/HeaderBox";
import AdminTabs from "./AdminTabs/AdminTabs";
import HeaderUi from "./headerUi";
import LocalFloristOutlinedIcon from '@mui/icons-material/LocalFloristOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import './Admin.css';
import Link from "react-router-dom";
import { useHistory } from "react-router-dom";

const Admin = () => {

    const [tab, setTab] = useState("Graves")
    const history = useHistory();
    const handleClick = () => {
        localStorage.setItem("Login", 'true');
        history.push(`/login`)
        return <>
        </>
    }
    return (
        <>
            {localStorage.getItem("Login") === 'false' ?
                <div>
                    <HeaderUi />
                    {/* <HeaderBox/> */}
                    <div className="h-screen">
                        <div id="default-sidebar" className="fixed left-0 z-40 w-64 h-full top-16 transition-transform -translate-x-full sm:translate-x-0 bg-[#4A3D4F] text-white " aria-label="Sidebar">
                            <div className="h-full px-3 py-2 overflow-y-auto">
                                <ul className="space-y-2">

                                    <li className={`${tab == "listGrave" || tab == "listAssignGrave" ? 'p-3 bg-[#9e9e9e] text-white rounded-lg cursor-pointer' : 'p-3 hover:bg-[#f1582a26] rounded-lg cursor-pointer'}`} onClick={() => setTab("listGrave")}>
                                        <AssignmentOutlinedIcon className="assign_grave" /> <span className="ml-3">List Grave</span>
                                    </li>
                                    <li className={`${tab == "assginGrave" ? 'p-3 bg-[#9e9e9e] text-white rounded-lg cursor-pointer' : 'p-3 hover:bg-[#f1582a26] rounded-lg cursor-pointer'}`} onClick={() => setTab("assginGrave")}>
                                        <AssignmentOutlinedIcon className="assign_grave" /> <span className="ml-3">Assign Grave</span>
                                    </li>
                                    <li className={`${tab == "Graves" ? 'p-3 bg-[#9e9e9e] text-white rounded-lg cursor-pointer' : 'p-3 hover:bg-[#f1582a26] rounded-lg cursor-pointer'}`} onClick={() => setTab("Graves")}>
                                        <LocalFloristOutlinedIcon className="graves" /> <span className="ml-3">Graves</span>
                                    </li>
                                    <li className={`${tab == "Sections" ? 'p-3 bg-[#9e9e9e] rounded-lg text-white cursor-pointer' : 'p-3 hover:bg-[#f1582a26] rounded-lg cursor-pointer'}`} onClick={() => setTab("Sections")}>
                                        <GridViewOutlinedIcon className="sections" /> <span className="ml-3">Sections</span>
                                    </li>
                                    <li className={`${tab == "Organizations" ? 'p-3 bg-[#9e9e9e] rounded-lg text-white cursor-pointer' : 'p-3 hover:bg-[#f1582a26] rounded-lg cursor-pointer'}`} onClick={() => setTab("Organizations")}>
                                        <CorporateFareOutlinedIcon className="organizations" /> <span className="ml-3">Organizations</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="pb-4 sm:ml-64 grid justify-items-center h-full"> */}
                        <div className="pb-4 sm:ml-64 h-full">

                            <div className="w-full h-[93vh] flex justify-center items-center">
                                <AdminTabs tab={tab} setTab={setTab} />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="flex justify-center h-[100vh]">
                    <div className="m-auto">
                        Please Login to access the features <span className="cursor-pointer text-[blue] underline" onClick={handleClick}>Login</span>
                    </div>
                </div>
            }
        </>
    )
}

export default Admin;