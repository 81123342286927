import React, { useState, useEffect } from "react";
import API from "../../../endpoints/endpoints";
import { Button } from "@mui/material";
import AddGrave from "./AddGrave";
import DataTable from "../../DataTable/DataTable";
import HeaderUi from "../../headerUi";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import RhfControl from "../../../rhfComponets/rhfControl";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';



const Graves = (props: any) => {

  const { setTab } = props;
  const [Tabs, setTabs] = useState('Graves');
  const [graves, setGraves] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [opens, setOpens] = React.useState(false);
  const [view, setView] = useState(false)


  useEffect(() => {
    getData();

  }, [])

  const getData = async () => {
    setLoading(true)
    await API.getGrave()
      .then((res: any) => {
        setGraves(res.data);
        setLoading(false);
      }
      )
  }
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleClickOpen = () => {
    setOpens(true);
  }

  const handleClose = () => {
    setView(false);
  }


  const columns = [
    // {
    //     field: 'grave_id',
    //     headerName: 'Grave ID',
    //     flex:1,
    //     align: "center",
    //     headerAlign: "center",
    //     sortable:false
    // },

    {
      field: 'organization',
      headerName: <b>Organization</b>,
      valueGetter: (params: any) => params.row.organization.org_l_name,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },

    {
      field: 'status',
      headerName: <b>Status</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },

    {
      field: 'section',
      headerName: <b>Section</b>,
      valueGetter: (params: any) => params.row.section.section_name,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },

  ];

  console.log("graves", graves);

  return (

    <>

      {!modal ?
        <div className="w-[98.5%] h-[75%]" style={{ overflowX: 'hidden' }}>
          <DataTable rows={graves} columns={columns} loading={loading} setSelectedItem={setSelectedItem} setModal={setModal} setView={setView} setEditModal={setEditModal} setTab={setTab} Tabs={Tabs} />
        </div>

        :
        <AddGrave setModal={setModal} setEditModal={setEditModal} selectedItem={selectedItem} getData={getData} setTab={setTab} />
      }

      {view &&
        <Dialog
          open={view}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={'lg'} // 'sm' || 'md' || 'lg' || 'xl'
        >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              {/* <div className= "w-[45%] h-[70%] flex flex-col bg-[white] rounded-[14px] px-5 pb-5">   */}
              <div className="text-center mt-5 "><span className="text-[#3B313F] text-2xl font-bold ">Grave Data</span></div>
              <Box sx={{ width: '100%' }} >
                <Stack spacing={3}>
                  <Item>Organization : {selectedItem.organization.org_l_name} </Item>
                  <Item>Status  :  {selectedItem.status}</Item>
                  <Item>Section :  {selectedItem.section.section_name}</Item>
                </Stack>
              </Box>
              {/* </div>  */}

            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button color="primary" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>}

    </>

  )
}

export default Graves;