import React, { useState } from "react";
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import AssignGrave from "../AdminTabs/AssignGrave";
import moment from "moment";


const ListGrave = (props: any) => {

    const { graveData, setShowForm, tab, setTab } = props;
    const [selectedItem, setSelectedItem] = useState<any>({});
    const [modal, setModal] = useState(false);



    const columns: any = [
        {
            field: 'first_name',
            headerName: 'First Name',
            flex: 1
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            flex: 1
        },
        {
            field: 'gender',
            headerName: 'Gender',
            flex: 1
        },
        {
            field: 'date_of_birth',
            headerName: 'Date of Birth',
            valueGetter: (params: any) => { return moment.unix(params.row.date_of_birth).format('MM-DD-YYYY') },
            flex: 1
        },
        {
            field: 'date_of_death',
            headerName: 'Date of Death',
            valueGetter: (params: any) => { return moment.unix(params.row.date_of_death).format('MM-DD-YYYY') },
            flex: 1
        },
        {
            field: 'organization',
            headerName: 'Organization',
            valueGetter: (params: any) => params.row.organization.org_l_name,
            flex: 1
        },
        {
            field: 'section',
            headerName: 'Section',
            valueGetter: (params: any) => params.row.section.section_name,
            flex: 1
        },
        {
            field: 'number',
            headerName: 'Grave Number',
            valueGetter: (params: any) => params.row.grave.number,
            flex: 1
        },
        {
            field: 'grave_status',
            headerName: 'Grave Status',
            valueGetter: (params: any) => params.row.grave.status,
            flex: 1
        },
        {
            field: "Edit",
            headerName: '',
            flex: 0.5,
            renderCell: () => (
                tab ? <div><Button sx={{ border: '1px solid white', color: '#3B313F', textTransform: "capitalize" }} onClick={() => handleEdit()}><EditOutlinedIcon className="edit" /> Edit</Button></div> : null
            ),
            hidden: true,
            align: "center"
        }

    ]
    const handleEdit = () => {
        setModal(true)
    }
    return (<div className={`${tab ? ' mt-[100px] w-[1200px] max-h-fit bg-[white] rounded-[14px] p-4' : 'w-[80%] bg-[white] rounded-[14px] p-4'}`}>
        {modal ? <div>
            <AssignGrave tab={tab} setTab={setTab} selectedItem={selectedItem} />
        </div> : <div >
            {!tab ? <div className="mb-4"><Button className="styledButtonPRIMERY1" startIcon={<KeyboardBackspaceIcon />} onClick={() => setShowForm(true)}>Back to Search</Button></div> : <div className="mb-4 text-right"><Button className="styledButtonPRIMERY1" startIcon={<AddIcon />} onClick={() => setTab("listAssignGrave")}>Assign Grave</Button></div>}
            <div className="">
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={graveData}
                        columns={columns}
                        getRowId={(params: any) => params.uuid}
                        onRowClick={params => setSelectedItem(params.row)}
                        hideFooter={true}
                        sx={{
                            color: 'black',
                            border: 'none',
                            //  '.css-v2lepk-MuiDataGrid-pinnedColumns':{backgroundColor:"#00000000"}, '.css-1oh5bxx-MuiDataGrid-pinnedColumnHeaders':{backgroundColor:"#00000000"}, '.css-1rwt2y5-MuiButtonBase-root-MuiButton-root':{color:"white", border:"1px solid white"},
                            "& .MuiDataGrid-row:hover": {

                            },
                            '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                                backgroundColor: "rgba(0, 0, 0, 0.272)"
                            },

                        }}
                    />
                </Box>
            </div>
        </div>}
    </div>)
}

export default ListGrave;