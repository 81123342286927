import React from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const Textfield = (props: any) => {

    
    const {name, control, required, label, fullWidth, disabled, textType} =props;

    return(
        <div className="">
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextField
                    size="small"
                        sx={{
                            '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {color:"black", border:'1px solid grey'},
                            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {WebkitTextFillColor: "darkgray"},
                            '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root':{backgroundColor:'',color: 'grey'},
                            '.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                                color: 'grey', backgroundColor:"white"
                            }

                        }}
                        required={required}
                        label={label}
                        fullWidth={fullWidth}
                        
                        disabled = {disabled}
                        onChange={onChange}
                        value={value}
                        type={textType}
                    />
                )}
            />
        </div>
    )
}

export default Textfield;