import React from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import API from "../../../endpoints/endpoints";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import RhfControl from "../../../rhfComponets/rhfControl";

const AddOrg = (props: any) => {

    const {setModal, selectedItem, getData} = props;

    const { handleSubmit, reset, control, formState, watch, setValue: setFormValue } = useForm({
        defaultValues: selectedItem,
      });

    const handleSave = async(data: any) => {
        await API.addOrganization(data).then(
            setModal(false),
            getData()
        )
    }


    return(
        <div className="w-[45%] h-[80%] flex flex-col bg-[white] rounded-[14px] px-5 pb-5">
            <div className="text-center mt-5"><span className="text-[#3B313F] text-2xl font-bold">{selectedItem.uuid ? "Edit":'Add'} Organization</span></div>
            <form className="p-2  h-[90%] flex flex-col justify-between" onSubmit={handleSubmit(data=> handleSave(data))}>
                <div>
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="org_s_name" label="organization short name" control={control} required={true} fullWidth={true} disabled={false}/>
                    </div>
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="org_l_name" label="organization full name" control={control} required={true} fullWidth={true} disabled={false}/>
                    </div>
                    
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="contact_name" label="contact name" control={control} required={true} fullWidth={true} disabled={false}/>
                    </div>
                    <div className="flex flex-row justify-between gap-2">


                    <div className="pt-6 w-1/2">
                        <RhfControl type="Textfeild" name="contact_phone" label="contact phone" control={control} required={true} fullWidth={true} disabled={false}/>
                    </div>
                    <div className="pt-6 w-1/2">
                        <RhfControl type="Textfeild" name="contact_email" label="contact email" control={control} required={true} fullWidth={true} disabled={false} textType="email"/>
                    </div>
                    </div>
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="org_address" label="organization address" control={control} required={true} fullWidth={true} disabled={false}/>
                    </div>
                </div>

                <div className="text-center flex flex-row justify-end mt-5 ">
                        <Button className="styledButton1" variant="outlined" onClick={()=>setModal(false)} sx={{width:"100px", color:'black', border:'1px solid black',margin:"0px 2px", "&:hover": { backgroundColor: "grey", color:"white", border:'1px solid grey'}}}>Cancel</Button>
                        <Button className="styledButtonPRIMERY1" variant="outlined" type="submit" sx={{width:"100px", color:'black', border:'1px solid black',margin:"0px 2px", "&:hover": { backgroundColor: "grey", color:"white", border:'1px solid grey'}}}>{selectedItem.uuid ? "Update":'Save'}</Button>
                </div>
            </form>
        </div>
    )
}

export default AddOrg;