import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Button, Tab, colors } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './DataTable.css';
import AddIcon from '@mui/icons-material/Add';
import { useDemoData } from '@mui/x-data-grid-generator';


import { cursorTo } from "readline";
import { relative } from "path/posix";

const DataTable = (props: any) => {

    const { rows, columns, loading, setSelectedItem, setModal, setEditModal, setTab, setView, Tabs } = props;
    console.log("rows", rows);
    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 100,
        maxColumns: 10,
    });
    const allColumns = [...columns,
    {
        field: "Edit",

        headerName: '',
        flex: 0.5,
        renderCell: () => (
            <div><Button sx={{ border: '1px solid white', color: '#3B313F', textTransform: "capitalize" }} onClick={() => handleEdit()}><EditOutlinedIcon className="edit" /> Edit</Button></div>
        ),
        align: "center"
    },
    {
        field: "View",

        headerName: '',

        renderCell: () => (
            <div>
                <Button sx={{ border: '1px solid white', color: '#3B313F', textTransform: "capitalize" }} onClick={() => setViewHandle()}><RemoveRedEyeOutlinedIcon className="view" /> View </Button>

            </div>
        ),
        align: "center"

    }
    ]
    const setViewHandle = () => {
        setView(true);
    }

    const handleAdd = () => {
        setSelectedItem({});
        setModal(true);
        setEditModal(false);

    }

    const handleEdit = () => {
        setSelectedItem({});
        setModal(true);
        setEditModal(true);
    }

    return (

        <div className="w-[82%] p-8 rounded-md bg-[white]" style={{ position: "fixed", overflowX: 'hidden' }} >
            <div className=" w-[100%] flex justify-between">
                <div style={{ fontWeight: "bolder", fontSize: "20px" }}>{Tabs}</div>
                {/* <div className="p-[6px]  " style={{ textAlign: "right" }}>
                <Button style={{ backgroundColor: "#C5863F" }}
                    onClick={handleAdd}
                    startIcon={<AddIcon />}
                    // sx={{ '.css-1o2af8o-MuiButtonBase-root-MuiButton-root': { color: "white", border: "1px solid white" }}}
                    sx={{ color: '#FFFFFF', textTransform: "capitalize" }}
                >
                    Add
                </Button>
                <CloseIcon onClick={() => setTab('')} style={{ cursor: "pointer", margin: "5px", color: "white" }} />
            </div> */}
                <div className="mb-4"><Button className="styledButtonPRIMERY1" startIcon={<AddIcon />} onClick={handleAdd}>Add</Button></div></div>

            <Box sx={{ height: 700, width: '100%', backgroundColor: "" }}>
                <DataGrid
                    {...data}
                    initialState={{
                        ...data.initialState,
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    key={rows.length}
                    rows={rows}
                    columns={allColumns}
                    // hideFooter={true}
                    getRowId={(params: any) => params.uuid}
                    loading={loading}

                    onRowClick={params => setSelectedItem(params.row)}
                    sx={{

                        color: 'black',
                        border: 'none',
                        '.css-v2lepk-MuiDataGrid-pinnedColumns': { backgroundColor: "#00000000" }, '.css-1oh5bxx-MuiDataGrid-pinnedColumnHeaders': { backgroundColor: "#00000000" }, '.css-1rwt2y5-MuiButtonBase-root-MuiButton-root': { color: "white", border: "1px solid white" },
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#f1582a26"
                        },
                        '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                            backgroundColor: "lightgray",




                        },
                    }}
                />
            </Box>
        </div>

    )
}

export default DataTable

