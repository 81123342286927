import React from "react";
import AssignGrave from "./AssignGrave";
import Graves from "./Grave/Graves";
import Organization from "./Organization/Organization";
import Section from "./Section/Section";
import { Link } from "react-router-dom";
import User from "../../pages/User";

const AdminTabs = (props: any) => {
    const { tab, setTab } = props;

    const renderTab = () => {
        switch (tab) {
            case 'Graves':
                return <Graves setTab={setTab} />;
            case 'listGrave':
                return <User setTab={setTab} tab={tab} />;
            case 'assginGrave':
                return <AssignGrave setTab={setTab} tab={tab} />;
            case 'listAssignGrave':
                return <AssignGrave setTab={setTab} tab={tab} />;
            case 'Sections':
                return <Section setTab={setTab} />;
            case 'Organizations':
                return <Organization setTab={setTab} />;
            default:
                return null;
        }

    }

    return (
        <>{renderTab()}</>
    )
}

export default AdminTabs;
