import React, { useState, useEffect } from "react";
import API from "../../../endpoints/endpoints";
import { Button } from "@mui/material";
import DataTable from "../../DataTable/DataTable";
import AddSection from "./AddSection";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import RhfControl from "../../../rhfComponets/rhfControl";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const Section = (props: any) => {

  const { setTab } = props;

  const [Tabs, setTabs] = useState('Sections');
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false)
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [opens, setOpens] = React.useState(false);
  const [view, setView] = useState(false)

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    setLoading(true)
    await API.getSection()
      .then((res: any) => {
        setSections(res.data);
        setLoading(false);
      }
      )
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleClose = () => {
    setView(false);
  }


  const columns = [

    {
      field: 'section_name',
      headerName: <b>Section </b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false

    },

    {
      field: 'description',
      headerName: <b>Description</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false

    },

    //        {
    //     field: 'uuid',
    //     headerName: 'ID',
    //     flex:1,
    //     align: "center",
    //     headerAlign: "center",
    //     sortable:false
    // }
  ];


  return (
    <>
      {!modal ?
        <div className="w-[98.5%] h-[75%]">
          <DataTable rows={sections} columns={columns} loading={loading} setSelectedItem={setSelectedItem} setModal={setModal} setEditModal={setEditModal} setView={setView} setTab={setTab} Tabs={Tabs} />
        </div>
        :
        <AddSection setModal={setModal} selectedItem={selectedItem} getData={getData} setTab={setTab} />

      }

      {view &&
        <Dialog
          open={view}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={'lg'} // 'sm' || 'md' || 'lg' || 'xl'
        >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              {/* <div className= "w-[45%] h-[70%] flex flex-col bg-[white] rounded-[14px] px-5 pb-5">   */}
              <div className="text-center mt-5 "><span className="text-[#3B313F] text-2xl font-bold ">Section Data</span></div>
              <Box sx={{ width: '200px', height: '150px' }} >
                <Stack spacing={3}>
                  <Item>Section  : {selectedItem.section_name} </Item>
                  <Item>Description  :  {selectedItem.description}</Item>
                </Stack>
              </Box>
              {/* </div>  */}

            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button color="primary" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }

    </>
  )
}

export default Section;