import React from "react";
import Textfield from "./feilds/Textfeild";
import Selectfeild from "./feilds/Select";
import SelectDate from "./feilds/SelectDate";

const RhfControl = (props: any) => {

    const {type, ...restProps} = props;

    switch(type){
        case "Textfeild":
            return <Textfield  {...restProps}/>
        case "Select":
            return <Selectfeild {...restProps}/>
        case "SelectDate":
            return <SelectDate {...restProps}/>
        default : 
            return null
    }
    
}

export default RhfControl;