import React, { useState, useEffect } from "react";
import API from "../../../endpoints/endpoints";
import { Button } from "@mui/material";
import DataTable from "../../DataTable/DataTable";
import AddOrg from "./AddOrg";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import RhfControl from "../../../rhfComponets/rhfControl";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Organization = (props: any) => {

  const { setTab } = props;
  const [Tabs, setTabs] = useState('Organizations');
  const [orgs, setOrgs] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [opens, setOpens] = React.useState(false);
  const [view, setView] = useState(false)

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    setLoading(true)
    await API.getOrganization()
      .then((res: any) => {
        setOrgs(res.data);
        setLoading(false);
      }
      )
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleClose = () => {
    setView(false);
  }

  const columns = [
    {
      field: 'org_s_name',
      headerName: <b>Short Name</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },
    {
      field: 'org_l_name',
      headerName: <b>Name</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },
    // {
    //     field: 'org_address',
    //     headerName: 'Organization Address',
    //     flex:1,
    //     align: "center",
    //     headerAlign: "center",
    //     sortable:false
    // },
    {
      field: 'contact_phone',
      headerName: <b>Contact Phone</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },
    {
      field: 'contact_name',
      headerName: <b>Contact Name</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },
    {
      field: 'contact_email',
      headerName: <b>E-mail</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false
    },
  ];


  return (
    <>

      {!modal ?
        <div className="w-[98.5%] h-[75%]">
          <DataTable rows={orgs} columns={columns} loading={loading} setSelectedItem={setSelectedItem} setView={setView} setModal={setModal} setTab={setTab} setEditModal={setEditModal} setTabs={setTabs} Tabs={Tabs} />
        </div>
        :
        <AddOrg setModal={setModal} selectedItem={selectedItem} getData={getData} setTab={setTab} />

      }

      {view &&
        <Dialog
          open={view}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={'lg'}
        >
          <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">


              <div className="text-center mt-5 "><span className="text-[#3B313F] text-2xl font-bold ">Organization Data</span></div>
              <Box sx={{}} >
                <Stack spacing={3}>
                  <Item>Short Name : {selectedItem.org_s_name} </Item>
                  <Item>Name : {selectedItem.org_l_name} </Item>
                  <Item>Contact Phone : {selectedItem.contact_phone} </Item>
                  <Item>Contact Name  :  {selectedItem.contact_name}</Item>
                  <Item>E-mail :  {selectedItem.contact_email}</Item>

                </Stack>
              </Box>


            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button color="primary" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>}

    </>
  )
}

export default Organization;