import React, { useState } from "react";
import RhfControl from "../../rhfComponets/rhfControl";
import API from "../../endpoints/endpoints";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { CircularProgress } from '@mui/material';
import HeaderUi from "../headerUi";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
// import '../UserTabs/pages/App.css'
import './SearchGrave.css'

const User = (props: any) => {

    const { setGraveData, setShowForm, tab, setTab } = props;

    const { handleSubmit, reset, control, formState, watch, setValue: setFormValue } = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            date_of_death: "",
            date_of_burial: "",
            organization: ""
        },
    });

    const [menu, setMenu] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const handleSave = async (data: any) => {
        setLoading(true)
        data['date_of_death'] = Date.parse(data['date_of_death']) / 1000;
        data['date_of_burial'] = Date.parse(data['date_of_burial']) / 1000;

        //deleting empty feilds
        Object.keys(data).map(item => {
            if (data[item] === "" || Number.isNaN(data[item])) {
                delete data[item];
            }
        })

        await API.searchGrave(data)
            .then((res: any) => {
                setGraveData(res.data);
                setLoading(false);
                setShowForm(false);
            })

    }

    const getOrgMenu = async () => {
        const response = await API.getOrgMenu();
        const temp: any[] = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.org_l_name })
        })
        setMenu(temp);
    }
    let objectDate = new Date();
    let day = objectDate.getDate();


    let month = objectDate.getMonth();


    let year = objectDate.getFullYear();

    let format4 = year + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);

    return (

        <div className={`${tab ? 'w-[800px] h-[100%] bg-white rounded-[24px] px-5 pb-5' : ' w-[40%] h-[80%] bg-white rounded-[24px] px-5 pb-5'}`}>
            {tab ? <div className="flex justify-between"> <div className="text-center mt-5"><span className="text-[#3B313F] text-2xl font-bold">Search Grave</span></div><div className="mt-4 text-right"><Button className="styledButtonPRIMERY1" startIcon={<AddIcon />} onClick={() => setTab("listAssignGrave")}>Assign Grave</Button></div></div> : <div className="text-center mt-5"><span className="text-[#3B313F] text-2xl font-bold">Search Grave</span></div>}
            {loading ? <CircularProgress sx={{ position: 'absolute', left: "calc(50% - 40px)", top: 'calc(50% - 40px)', color: "#f1592a", zIndex: "1" }} /> : null}
            <form className="p-2  h-[90%] flex flex-col justify-between" onSubmit={handleSubmit(data => handleSave(data))}>
                <div>
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="first_name" label="First Name" control={control} required={false} fullWidth={true} disabled={false} />
                    </div>
                    <div className="pt-6">
                        <RhfControl type="Textfeild" name="last_name" label="Last Name" control={control} required={false} fullWidth={true} disabled={false} />
                    </div>
                    <div className="pt-6">
                        <RhfControl
                            type="SelectDate"
                            label="Date Of Death"
                            name="date_of_death"
                            required={false}
                            fullWidth={true}
                            disabled={false}
                            control={control}
                            format={format4}
                        />
                    </div>
                    <div className="pt-6">
                        <RhfControl
                            type="SelectDate"
                            label="Date Of Burial"
                            name="date_of_burial"
                            required={false}
                            fullWidth={true}
                            // disabled={false}
                            control={control}
                            format={format4}
                        />
                    </div>
                    <div className="pt-6">
                        <RhfControl type="Select" name="organization" label="Select Organization" control={control} required={false} fullWidth={true} getMenu={getOrgMenu} menu={menu} className="placeholder" />
                    </div>
                </div>

                <div className="text-center  flex justify-end mt-5">
                    <Button variant="outlined" className="styledButtonPRIMERY1" type="submit" disabled={formState.isDirty ? false : true} sx={{ width: "100px", color: 'black', border: '1px solid black', "&:hover": { backgroundColor: "grey", color: "white", border: '1px solid grey' } }}>Search</Button>
                </div>
            </form>
        </div>


    )
}

export default User;