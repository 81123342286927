import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import HeaderBox from '../layout/HeaderBox';


function HeaderUi() {

  const history = useHistory();
  const handleClick = () => {
    localStorage.setItem("Login", 'true');
    history.push(`/login`)
    return <>
    </>
  }
  <HeaderBox />

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: "#3B313F", position: 'fixed' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >

          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Marlboro Muslim Memorial
          </Typography>
          <Button color="inherit" onClick={handleClick}><LogoutOutlinedIcon />LogOut</Button>

        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default HeaderUi