import React from "react";
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import API from "../../../endpoints/endpoints";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import RhfControl from "../../../rhfComponets/rhfControl";
import DataTable from "../../DataTable/DataTable";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// @ts-ignore
import _ from 'lodash';
import { error, log } from "console";

const AddGrave = (props: any) => {
    const { setModal, setEditModal, selectedItem, getData } = props;
    const empty = _.isEmpty(selectedItem);
    const sectionName = "";

    const [open, setOpen] = useState(false);


    useEffect(() => {
        getOrgMenu();
        getSectionMenu();
        getNumberMenu();
    }, [])


    const uuidGenerator = () => {
        const S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(10).substring(1);
        };
        return S4();
    }

    const { handleSubmit, reset, control, formState, watch, setValue } = useForm({
        // defaultValues:{grave_id:uuidGenerator(),section:selectedItem.section,organization:""},
        defaultValues: { ...selectedItem, grave_id: (empty ? uuidGenerator() : selectedItem.grave_id), section: selectedItem.section?.uuid, organization: selectedItem.organization?.uuid },
    });

    const [sectionMenu, setSectionMenu] = useState([]);
    const [numberMenu, setNumberMenu] = useState([]);
    const [orgMenu, setOrgMenu] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const getSectionMenu = async () => {
        const response = await API.getSectionMenu();
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.section_name })
            let sectionName = item.section_name;
        })
        setSectionMenu(temp);
    }

    const getNumberMenu = async () => {
        const response = await API.getSectionNumber();
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.number })
        })
        setNumberMenu(temp);
    }

    const getOrgMenu = async () => {
        const response = await API.getOrgMenu();
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.org_l_name })
        })
        setOrgMenu(temp);
    }

    const handleSave = async (data: any) => {
        await API.addGrave(data).then(() => {
            setModal(false);
            getData();
        })
            .catch((error) => {
                // Handle the error

                if (error.response.data.reason === "already_exists") {

                    setOpen(true);
                }
            }
            )

    }
    useEffect(() => {


    }, [open])

    return (

        <div className="w-[45%] h-[70%] flex flex-col bg-[white] rounded-[14px] px-5 pb-5">
            <div className="text-center mt-5"><span className="text-[##3B313F] text-2xl font-bold">{selectedItem.uuid ? "Edit" : 'Add'} Grave</span></div>
            <form className="p-2  flex-1 flex flex-col  justify-between" onSubmit={handleSubmit(data => handleSave(data))}>
                <div>
                    <div className="pt-6">
                        <RhfControl name="grave_id" control={control} required={true} fullWidth={true} disabled={true} />
                    </div>

                    <div className="pt-6 ">
                        <RhfControl type="Select" name="section" label="select section" control={control} required={true} fullWidth={true} getMenu={getSectionMenu} menu={sectionMenu} />
                    </div>

                    <div className="pt-6 ">
                        <RhfControl type="Textfeild" name="number" label="grave number" control={control} required={true} fullWidth={true} getMenu={getNumberMenu} menu={numberMenu} />
                    </div>

                    <div className="pt-6">
                        <RhfControl type="Select" name="organization" label="select organization" control={control} required={true} fullWidth={true} getMenu={getOrgMenu} menu={orgMenu} />
                    </div>

                </div>

                <div className="text-center  flex flex-row justify-end mt-5">
                    <Button className="styledButton1" variant="outlined" onClick={() => setModal(false)} sx={{ width: "100px", color: 'black', border: '1px solid black', margin: "0px 2px", "&:hover": { backgroundColor: "grey", color: "white", border: '1px solid grey' } }}>Cancel</Button>
                    <Button className="styledButtonPRIMERY1" variant="outlined" type="submit" sx={{ width: "100px", color: 'black', border: '1px solid black', margin: "0px 2px", "&:hover": { backgroundColor: "grey", color: "white", border: '1px solid grey' } }}>{selectedItem.uuid ? "Update" : 'Save'}</Button>
                </div>
            </form>

            {open &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Alert!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Grave already exist in this number
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </Dialog>
            }
        </div >
    )

}

export default AddGrave;