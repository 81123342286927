import React, { useEffect } from "react";
import { useState } from "react";
import Button from '@mui/material/Button';
import API from "../../endpoints/endpoints";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import RhfControl from "../../rhfComponets/rhfControl";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';


const AssignGrave = (props: any) => {

    const { setTab, tab, selectedItem } = props;
    console.log("organization", selectedItem);

    const { handleSubmit, reset, control, formState, watch, setValue: setFormValue } = useForm({
        defaultValues: {

            grave: selectedItem?.grave?.uuid ? selectedItem?.grave?.uuid : "",
            first_name: selectedItem?.first_name ? selectedItem?.first_name : "",
            middle_name: selectedItem?.middle_name ? selectedItem?.middle_name : "",
            section_name: selectedItem?.section?.uuid ? selectedItem?.section?.uuid : "",
            organization: selectedItem?.organization?.uuid ? selectedItem?.organization?.uuid : "",
            last_name: selectedItem?.last_name ? selectedItem?.last_name : "",
            date_of_birth: selectedItem?.date_of_birth ? moment.unix(selectedItem?.date_of_birth).format('YYYY-MM-DD') : 0,
            date_of_death: selectedItem?.date_of_death ? moment.unix(selectedItem?.date_of_death).format('YYYY-MM-DD') : 0,
            date_of_burial: selectedItem?.date_of_burial ? moment.unix(selectedItem?.date_of_burial).format('YYYY-MM-DD') : 0,
            gender: selectedItem?.gender ? selectedItem?.gender : ""
        },
    });

    const [sectionMenu, setSectionMenu] = useState([])
    const [orgMenu, setOrgMenu] = useState([]);
    const [graveNumber, setgraveNumber] = useState([])
    const [open, setOpen] = useState(false);
    const section_id = watch("section_name")
    const organization_id = watch("organization")
    const grave = watch("grave")
    const date_of_death = watch("date_of_death")

    console.log("date_of_death", date_of_death)


    const handleClose = () => {
        setOpen(false);
        setTab("listGrave")
    };

    const handleSave = async (data: any) => {
        data['date_of_birth'] = Date.parse(data['date_of_birth']) / 1000;
        data['date_of_death'] = Date.parse(data['date_of_death']) / 1000;
        data['date_of_burial'] = Date.parse(data['date_of_burial']) / 1000;
        data['grave'] = grave;
        if (selectedItem?.uuid) {
            await API.updateAssign(selectedItem?.uuid, data).then(() => {
                reset() as any
                setOpen(true);

            })
                .catch((error) => {

                    console.log("failed")

                }
                )
        } else {
            await API.assignGrave(data).then(() => {
                reset() as any
                setOpen(true);

            })
                .catch((error) => {

                    console.log("failed")

                }
                )
        }
    }
    let objectDate = new Date();
    let day = objectDate.getDate();

    let month = objectDate.getMonth();

    let year = objectDate.getFullYear();

    let format4 = year + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);

    const getSectionName = async () => {
        const response = await API.getSectionMenu();
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.section_name })
        })
        setSectionMenu(temp);
    }
    console.log("testing1234", section_id);

    const getOrgMenu = async () => {
        const response = await API.getOrgMenu();
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.org_l_name })

        })
        setOrgMenu(temp);
    }

    const getGraveNumber = async () => {

        const response = await API.getGraveFilter(section_id, organization_id);
        const temp: any = [];
        response.data.map((item: any) => {
            temp.push({ value: item.uuid, option: item.number })
        })
        if (selectedItem?.uuid) {
            temp.push({ value: selectedItem?.grave?.uuid, option: selectedItem?.grave?.number })
        }
        setgraveNumber(temp);
    }

    useEffect(() => {
        getSectionName()
        getOrgMenu()
    }, [])
    useEffect(() => {
        if (section_id) {
            getGraveNumber()
        }
    }, [section_id])
    return (
        <div className="w-[50%] h-[70%] bg-[white] rounded-[14px] flex flex-col px-5 pb-5" >
            <div className="text-center mt-5"><span className="text-[#3B313F] text-2xl font-bold">{selectedItem?.uuid && 'Edit'} Assign Grave</span></div>
            <form className="p-2 flex-1   flex flex-col justify-between" onSubmit={handleSubmit(data => handleSave(data))}>
                <div>

                    <div className="flex flex-row w-full justify-between  gap-[8px]">
                        <div className="pt-6 w-1/3">
                            <RhfControl type="Textfeild" name="first_name" label="first name" control={control} required={true} fullWidth={true} disabled={false} />
                        </div>
                        <div className="pt-6 w-1/3 ">
                            <RhfControl type="Textfeild" name="middle_name" label="middle name" control={control} required={false} fullWidth={true} disabled={false} />
                        </div>
                        <div className="pt-6 w-1/3 ">
                            <RhfControl type="Textfeild" name="last_name" label="last name" control={control} required={true} fullWidth={true} disabled={false} />
                        </div>
                    </div>

                    {/* <div className="flex flex-row w-full justify-between  gap-[8px]"> */}
                    {/* <div className="pt-6  w-[66.5%]">
                        <RhfControl type="Select" name="grave" label="Select Grave ID" control={control} required={true} fullWidth={true} getMenu={getGraveId} menu={menu}/>
                    </div> */}
                    <div className="flex flex-row w-full   gap-[8px]">
                        <div className="pt-6  w-1/3">
                            <RhfControl type="Select" name="section_name" label="section" control={control} required={true} fullWidth={true} menu={sectionMenu} />
                        </div>
                        <div className="pt-6 w-[60%] ">
                            <RhfControl type="Select" name="organization" label="select organization" control={control} required={true} fullWidth={true} menu={orgMenu} />

                        </div>

                    </div>

                    <div className="flex flex-row w-full   gap-[8px]">
                        <div className="pt-6  w-1/3">
                            <RhfControl type="Select" name="grave" label="grave number " control={control} required={true} fullWidth={true} menu={graveNumber} />
                        </div>
                        <div className="pt-6 w-[32.5%]">
                            <RhfControl type="Select" name="gender" label="gender" control={control} required={false} fullWidth={true} menu={[{ value: 'Male', option: "Male" }, { value: "Female", option: "Female" }, { value: "Unknown", option: "Unknown" }]} />
                        </div>


                    </div>
                    {/* </div> */}

                    <div className="flex flex-row w-full justify-between gap-[8px]">

                        <div className="pt-6 w-1/3 ">

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DesktopDatePicker
              className="w-full h-[10px]"
                label={node.name + (node.required ? "*" : "")}
                minDate={node.date ? new Date(node.date) : null}
                inputFormat="DD/MM/YYYY"
                views={["year", "month", "day"]}
                disabled={node.disabled ? node.disabled : null}
                value={node.value}
                maxDate={node.maxdate ? new Date(node.maxdate) : null}
                onChange={handleChange}
                format="MM/DD/YYYY"
                
                
                PopperProps={{ placement: "bottom", style: { zIndex: "9999" } }}
              />
            </Stack>
          </LocalizationProvider> */}
                            <RhfControl
                                type="SelectDate"
                                label="Date Of Birth"
                                name="date_of_birth"
                                required={true}
                                fullWidth={true}
                                disabled={false}
                                control={control}
                                format={format4}
                            />


                        </div>
                        <div className="pt-6 w-1/3 ">
                            <RhfControl
                                type="SelectDate"
                                label="Date Of Death"
                                name="date_of_death"
                                required={true}
                                fullWidth={true}
                                disabled={false}
                                control={control}
                                format={format4}
                            />
                        </div>
                        <div className="pt-6 w-1/3 ">
                            <RhfControl
                                type="SelectDate"
                                label="Date Of Burial"
                                name="date_of_burial"
                                required={true}
                                fullWidth={true}
                                disabled={false}
                                control={control}
                            />
                        </div>
                    </div>

                </div>

                <div className="text-center  flex flex-row justify-end mt-5">
                    {tab == "listAssignGrave" && <Button className="styledButton1" variant="outlined" onClick={() => setTab("listGrave")} sx={{ width: "100px", color: 'black', border: '1px solid black', margin: "0px 2px", "&:hover": { backgroundColor: "grey", color: "white", border: '1px solid grey' } }}>Cancel</Button>}
                    <Button className="styledButtonPRIMERY1" variant="outlined" type="submit" sx={{ width: "100px", color: 'black', border: '1px solid black', margin: "0px 2px", "&:hover": { backgroundColor: "grey", color: "white", border: '1px solid grey' } }}>Save</Button>
                </div>
            </form>

            {open &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Success!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Grave assigned successfully
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

export default AssignGrave;


